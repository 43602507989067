import React from 'react';
import './GradientBackground.scss';

function GradientBackground() {

	return (
		<div className="gradient-background"></div>
	);
}

export default GradientBackground;
